@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');
.player-wrapper {
  /* border: 7px solid rgba(81, 107, 235, 0.5); */
  /* box-shadow: 0px 25px 42px rgba(81, 107, 235, 0.25); */
  border-radius: 10px;
}

.react-player__shadow {
  background-color: #f48c06 !important;
}

.react-player__preview {
  height: 300px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.monaco-editor span {
  font-size: 13px !important;
}

.monaco-editor-button {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  width: 100%;
  max-width: 17rem;
  min-width: 12rem;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 1.75rem;
  border: 2px solid rgb(0, 0, 0);
  outline: 0px !important;
}

.textarea-monaco {
  border: 2px solid rgb(0, 0, 0);
  box-shadow: rgb(0, 0, 0) 5px 5px 0px 0px;
  border-radius: 0.375rem;
}

.css-qc6sy-singleValue {
  font-size: 13px;
}

/* slider carousel */
.slides-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-arrows {
  display: flex;
}

.custom-arrows button {
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  background: none;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: #344054;
  padding: 12px 25px;
}

.custom-arrows #font {
  font-size: 16px;
  fill: #344054;
  margin-bottom: 3px;
  margin-left: 10px;
}

.custom-arrows button:first-of-type {
  margin-right: 15px;
}

.custom-arrows button:first-of-type #font {
  margin-left: 0;
  margin-right: 10px;
}

.feedback-slider {
  margin-top: 100px;
  padding: 0 160px;
}

.slider-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.slider-heading h2 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 32px;
  color: #6499e9;
}

.slider-heading p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #696984;
}

.review-box {
  background: #ffffff;
  border: 1px solid #e7eaec;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  min-height: 30vh;
  margin-right: 20px;
}

.review-box h3 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 22px;
  color: #667085;
  margin-bottom: 20px;
}

.review-box p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .slick-arrow {
    display: none !important;
  }
}

/* ------------styling for mobile view--------------- */
@media only screen and (max-width: 600px) {
  .slick-arrow {
    display: none !important;
  }

  .feedback-slider {
    padding: 0 20px;
    margin: 0;
  }

  .slider-heading {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .slider-heading h2 {
    font-weight: 600;
    font-size: 22px;
    text-align: left;
    line-height: 30px;
  }

  .slider-heading p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #002797;
  }

  .review-box {
    margin-top: 20px;
    min-height: 24vh;
  }

  .review-box h3 {
    margin-top: 10px;
  }

  .custom-arrows button {
    padding: 5px 10px;
    font-size: 14px;
  }
}

/* Carousel */
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.myCarousel {
  background: #d4dbff;
  border-radius: 19.2593px;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 0px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h2 {
  font-weight: 700;
  color: #002797;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #516beb;
  font-size: 15px;
  font-family: sans-serif;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #516beb !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
  padding: 0 15px;
  border-radius: 50%;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #d4dbff;
    border-radius: 19.2593px;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    height: 269px;
  }
}
