h1,
p,
label,
input,
textarea,
.mobile-input {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  display: block;
}

.contact-page {
  display: grid;
  grid-template-columns: 55% 45%;
}

.contact-form {
  padding: 100px 150px;
}

.contact-form h1 {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #101828;
}

.contact-form p {
  font-size: 2rem;
  font-weight: 400;
  color: #667085;
}

.contact-form form {
  margin-top: 40px;
}

.contact-form form div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.contact-form form label {
  color: #344054;
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
}

.contact-form form input,
.contact-form form textarea {
  border: 0.89px solid #d0d5dd;
  border-radius: 8px;
  color: #667085;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 5px 0 30px 0;
  padding: 10px 14px;
  width: 100%;
}

.mobile-input {
  border-radius: 8px;
  color: #667085;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 5px 0 30px 0;
  width: 100%;
}

.contact-form .error {
  color: red;
  margin-top: -20px;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.contact-form form select {
  border: 0.89px solid #d0d5dd;
  color: #344054;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 5px 0 30px 0;
  padding: 10px 3rem 14px 14px;
  /* for select marker */
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 50%;
}

.contact-form form select option {
  font-size: 1.8rem;
  font-weight: 400;
}

.contact-form form input::placeholder {
  color: #667085;
}

.contact-form form select:focus,
.contact-form form input:active,
.contact-form form input:focus {
  border: 2px solid rgb(160, 163, 169);
  outline: #fff;
}
.contact-form form p {
  display: flex;
  align-items: center;
  margin: 0;
}

.contact-form form p input {
  cursor: pointer;
  margin: 0;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.contact-form form button {
  background-color: #6499e9;
  border: 1px solid #6499e9;
  border-radius: 8px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  width: 100%;
}

.contact-image img {
  object-fit: cover;
  object-position: right;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .contact-page {
    grid-template-columns: 1fr;
  }

  .contact-form {
    padding: 40px 20px;
    padding-top: 100px;
  }

  .contact-form h1 {
    margin-bottom: 10px;
  }

  .contact-form form div {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .contact-form form select {
    width: 100%;
  }

  .contact-form form p {
    font-size: 1.4rem;
  }

  .contact-image {
    display: none;
  }
}
