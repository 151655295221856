/* -----------partners-and-clients styling starts here-------------- */
.partners-and-clients {
  margin: 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d0d5dd;
  padding: 30px 0;
}

.partners-and-clients h2 {
  color: #667085;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 70px;
}

.partners-and-clients div img {
  height: 6vh;
  margin-right: 70px;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {
  /* -----------partners-and-clients styling starts here-------------- */
  .partners-and-clients {
    margin: 50px 0;
    padding: 60px 0;
  }

  .partners-and-clients h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto 60px auto;
    width: 90%;
  }

  .partners-and-clients img {
    height: 25px;
  }
}
