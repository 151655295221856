@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');
nav {
  display: initial;
}

.desktop-nav-parent {
  background-color: #fff;
  z-index: 99999;
  position: sticky;
  width: 100%;
  top: 0%;
}

/* desktop screen styling starts here */
.mobile-navbar {
  display: none;
}

.desktop-navbar {
  border-bottom: 1px solid var(--Secondary, #ddf2ff);
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 6rem;
}

.icon-logo {
  display: flex;
  align-items: flex-end;
}

.icon-logo h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.desktop-navbar-img {
  margin-right: 10px;
}

.desktop-nav-links {
  display: flex;
  align-items: center;
}

.desktop-nav-list {
  color: #667085;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-left: 50px;
  line-height: 22px;
  text-decoration: none;
}

.desktop-nav-links-search-icon {
  cursor: pointer;
  fill: #6499e9;
  font-size: 25px;
  font-size: 2.4rem;
  margin-left: 35px;
}

.login-link {
  background-color: #6499e9;
  color: #fff;
  font-size: 1.6em;
  font-weight: bold;
  margin-left: 35px;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 5px;
}

.login-link:hover {
  background-color: #2141e0;
  color: #fff;
}

/* Small Devices, Tablets */
@media screen and (min-width: 768px) and (max-width: 960px) {
  .desktop-navbar {
    display: none;
  }

  nav {
    padding-bottom: 55px;
  }

  .mobile-navbar {
    width: 100%;
    display: block;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 9999;
  }

  .mobile-nav-body {
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    border-bottom: 3px solid #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 20px;
  }

  .mobile-nav-body section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-nav-body section img {
    width: 40px;
    height: 25px;
    margin-right: 10px;
  }

  .mobile-nav-body section h3 {
    font-size: 18px;
    margin: 0;
    align-self: flex-end;
  }

  .mobile-nav-button {
    border-radius: 8px;
    border: 1px solid #6499e9;
    background: #6499e9;
    color: #fff;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    padding: 8px 15px;
  }

  /* hamburger menu styling */
  .mobile-hamburger-btn {
    width: 28px;
    height: 4px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    margin-left: 20px;
  }

  .mobile-hamburger-btn::after,
  .mobile-hamburger-btn::before {
    content: ' ';
    position: absolute;
    width: 28px;
    height: 4px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::before {
    transform: translateY(-10px);
  }

  .mobile-hamburger-btn::after {
    transform: translateY(10px);
  }

  /* mobile-hamburger animation */
  .mobile-hamburger-btn.close {
    transform: translateX(-50px);
    background: transparent;
  }

  .mobile-hamburger-btn.close::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-hamburger-btn.close::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* nav links style on mobile view */
  .mobile-nav-links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #d4dbff;
    clip-path: inset(0% 0% 100% 0%);
    -webkit-clip-path: inset(0% 0% 100% 0%);
    transition: all 0.5s ease-out;
    pointer-events: none;
    padding: 30px;
  }

  .mobile-nav-list {
    color: #6499e9;
    list-style: none;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 30px;
  }

  .mobile-nav-list:nth-last-child(1) {
    background-color: #002797;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    width: fit-content;
  }

  .mobile-nav-list:hover {
    color: #f9b326;
    text-decoration: underline;
  }

  .mobile-nav-links.open {
    clip-path: inset(0% 0% 0% 0%);
    -webkit-clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;
  }
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {
  nav {
    padding-bottom: 55px;
  }

  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 9999;
  }

  .mobile-nav-body {
    background-color: #fff;
    border-bottom: 1px solid #ddf2ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 60px;
  }

  .mobile-nav-body section {
    display: flex;
    align-items: center;
  }

  .mobile-nav-body section img {
    width: 30px;
    height: 17px;
    margin-right: 5px;
  }

  .mobile-nav-body section h3 {
    color: #382b47;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }

  .mobile-nav-button {
    border-radius: 8px;
    border: 1px solid #6499e9;
    background: #6499e9;
    color: #fff;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    padding: 7px 15px;
  }

  .mobile-nav-button:hover {
    color: #fff;
  }

  /* hamburger menu styling */
  .mobile-hamburger-btn {
    width: 26px;
    height: 3px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    margin-left: 20px;
  }

  .mobile-hamburger-btn::after,
  .mobile-hamburger-btn::before {
    content: ' ';
    position: absolute;
    width: 26px;
    height: 3px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::before {
    transform: translateY(-10px);
  }

  .mobile-hamburger-btn::after {
    transform: translateY(10px);
  }

  /* mobile-hamburger animation */
  .mobile-hamburger-btn.close {
    transform: translateX(-50px);
    background: transparent;
  }

  .mobile-hamburger-btn.close::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-hamburger-btn.close::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* nav links style on mobile view */
  .mobile-nav-links {
    background-color: #d4dbff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    clip-path: inset(0% 0% 100% 0%);
    -webkit-clip-path: inset(0% 0% 100% 0%);
    transition: all 0.5s ease-in-out;
    pointer-events: none;
    padding-top: 20px;
  }

  .mobile-nav-list {
    color: #6499e9;
    list-style: none;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 20px;
  }

  .mobile-nav-list:hover {
    text-decoration: underline;
  }

  .mobile-nav-links.open {
    clip-path: inset(0% 0% 0% 0%);
    -webkit-clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;
  }
}
