@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');

h1,
h2,
h3,
h4,
p,
li {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-family: 'Inter', sans-serif;
}

.pricing-page {
  padding: 50px 100px;
  background-image: linear-gradient(
    350deg,
    hsl(0deg 0% 100%) 1%,
    hsl(204deg 100% 100%) 37%,
    hsl(204deg 100% 99%) 45%,
    hsl(204deg 100% 99%) 49%,
    hsl(204deg 100% 98%) 50%,
    hsl(204deg 100% 98%) 51%,
    hsl(204deg 100% 97%) 51%,
    hsl(204deg 100% 97%) 50%,
    hsl(203deg 100% 97%) 50%,
    hsl(203deg 100% 96%) 49%,
    hsl(203deg 100% 96%) 49%,
    hsl(203deg 100% 95%) 50%,
    hsl(203deg 100% 95%) 51%,
    hsl(203deg 100% 94%) 55%,
    hsl(203deg 100% 94%) 63%,
    hsl(203deg 100% 93%) 99%
  );
}

.pricing-page h4,
.pricing-page h1,
.pricing-page h3,
.pricing-page h2,
.pricing-page p {
  text-align: center;
}

.pricing-page h4 {
  border-radius: 18px;
  color: #6499e9;
  font-size: 1.4rem;
  font-weight: 500;
  background: #bbdff5;
  width: fit-content;
  margin: auto;
  padding: 8px 12px;
}

.pricing-page h1 {
  font-size: 4.8rem;
  font-weight: 600;
  color: #667085;
  margin: 20px 0;
}

.pricing-page p {
  font-size: 2rem;
  font-weight: 400;
  color: #6499e9;
  margin-bottom: 70px;
}

.pricing-page .popularHeading {
  position: relative;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

.pricing-page .popularHeading h2 {
  color: #6499e9;
  font-size: 1.6rem;
  font-weight: 500;
}

.pricing-page .popularHeading img {
  margin-right: 5px;
  position: absolute;
  top: 40%;
  right: 100%;
  height: 60px;
}

.pricing-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pricing-card div {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: #10182814 0px 12px 18px 0px;
  padding: 50px 20px;
  width: 450px;
  height: fit-content;
}

.pricing-card div:first-child {
  margin-right: 60px;
}

.pricing-card div h2 {
  font-size: 4.8rem;
  font-weight: 600;
  color: #101828;
  margin-bottom: 20px;
}

.pricing-card div h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #101828;
  margin-bottom: 10px;
}

.pricing-card div p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #667085;
  margin-bottom: 40px;
}

.pricing-card div li {
  list-style: none;
  font-weight: 1.6rem;
  font-weight: 400;
  color: #667085;
  margin-bottom: 20px;
  display: flex;
}

.pricing-card div img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.pricing-card div a {
  background-color: #6499e9;
  border: 1px solid #6499e9;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* features */
.features {
  margin: 100px 150px;
}

.features section:first-child {
  width: 60%;
}

.features h4 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #6499e9;
}

.features h2 {
  font-size: 3.6rem;
  font-weight: 600;
  color: #101828;
  margin-bottom: 20px;
  width: 90%;
}

.features p {
  font-size: 2rem;
  font-weight: 400;
  color: #667085;
}

.different-features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  margin: 70px 0;
}

.different-features img {
  width: 70px;
  height: 70px;
}

.different-features h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #101828;
  margin: 20px 0 10px 0;
}

.different-features p {
  font-size: 1.8rem;
  font-weight: 400;
  color: #667085;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 600px) {
  .pricing-page {
    padding: 70px 20px;
  }

  .pricing-page h4 {
    font-size: 1.3rem;
  }

  .pricing-page h1 {
    font-size: 2.8rem;
    margin: 10px 0;
  }

  .pricing-page p {
    font-size: 1.4rem;
    margin-bottom: 70px;
  }

  .pricing-page .popularHeading {
    margin-right: 40px;
    margin-bottom: 10px;
  }

  .pricing-page .popularHeading img {
    height: 40px;
  }

  .pricing-page .popularHeading h2 {
    font-size: 1.4rem;
  }

  .pricing-card div {
    padding: 30px 20px;
    width: 100%;
    box-shadow: #10182814 0px 2px 6px 3px;
  }

  .pricing-card div:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .pricing-card div h2 {
    font-size: 2.4rem;
    margin: 0;
    margin-bottom: 5px;
  }

  .pricing-card div p {
    margin-bottom: 30px;
  }

  .pricing-card ul li img {
    width: 20px;
    height: 20px;
    margin-left: -10px;
  }

  /* features */
  .features {
    margin: 40px 20px;
  }

  .features section:first-child {
    width: 100%;
  }

  .features h4 {
    margin-bottom: 10px;
  }

  .features h2 {
    font-size: 2.6rem;
    width: 100%;
  }

  .different-features {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin: 30px 0;
  }
}
