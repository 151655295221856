.course-description {
	position: relative;
}

.blue-background {
	background: #2f327d;
	width: 100%;
	height: 10rem;
	position: absolute;
	top: -1rem;
	z-index: -1;
}

.course-description-heading {
	display: flex;
	align-items: center;
	padding: 2% 4%;

	height: 5rem;
}

.course-description-heading img {
	width: 40%;
	object-fit: contain;
}

.course-description-intro {
	width: 100%;
	height: 8rem;
	padding: 2% 5%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	height: fit-content;
}

.course-thumbnail {
	width: 25vw !important;
	height: 30vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.react-player {
	position: absolute;
	padding: 5px;
	border: 5px solid #516beb;
}

.play-icon-body {
	display: none;
	position: absolute;
	background: var(--primary-bg);
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	height: 60px;
	width: 60px;
	padding-left: 5px;
	cursor: pointer;
}

.play-icon {
	fill: #fff;
	font-size: 50px;
}

.course-description-intro .course-thumbnail img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.course-thumbnail:hover .play-icon-body {
	cursor: pointer;
	display: flex;
	animation: zoom-in 250ms ease-in-out alternate;
}

@keyframes zoom-in {
	0% {
		transform: scale(0.6, 0.6);
	}
	50% {
		transform: scale(0.8, 0.8);
	}
	100% {
		transform: scale(1, 1);
	}
}

.course-description-intro > div {
	width: 50%;
}

.course-description-intro > div:nth-child(2) {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
}

.course-description-intro .course-title {
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	font-family: 'Inter', sans-serif;
}

.go-to-course-btn {
	margin-top: 6rem;
}

.go-to-course-btn button {
	width: 12rem;
	height: 5rem;
	font-size: 14px;
	background: #516beb;
	border-radius: 4px;
	color: #fff;
	border: none;
}

.course-description-content {
	margin-top: 3rem;
	padding: 0 5% 4rem;
}

.course-description-content h5 {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-size: 20px;
	color: #000000;
}

.course-description-content p {
	font-family: 'Poppins', sans-serif;
	color: #000000;
	font-size: 16px;
}

.course-description-content li {
	font-size: 16px;
}

/* Responsive implementation */
@media screen and (max-width: 450px) {
	.blue-background {
		height: 13rem;
	}

	.course-description-heading {
		height: 3rem;
	}

	.course-description-heading img {
		width: 35%;
	}

	.course-description-intro {
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}

	.course-description-intro .course-thumbnail {
		margin-bottom: 1rem;
	}

	.course-description-intro .course-thumbnail img {
		width: 100%;
	}

	.course-description-intro > div {
		width: 100%;
	}

	.course-description-intro > div:nth-child(2) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
	}

	.go-to-course-btn {
		position: static;
		margin-top: 3rem;
	}
}

@media screen and (min-width: 451px) and (max-width: 767px) {
	.blue-background {
		height: 13rem;
	}

	.course-description-heading {
		height: 3rem;
	}

	.course-description-heading img {
		width: 35%;
	}

	.course-description-intro {
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}

	.course-description-intro .course-thumbnail {
		margin-bottom: 1rem;
	}

	.course-description-intro .course-thumbnail img {
		width: 100%;
	}

	.course-description-intro > div {
		width: 100%;
	}

	.course-description-intro > div:nth-child(2) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
	}

	.go-to-course-btn {
		position: static;
		margin-top: 3rem;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.course-description-intro .course-thumbnail img {
		width: 90%;
	}
}

@media (max-width: 767px) {
	.course-thumbnail {
		display: none;
	}
	.course-description-content hr {
		display: none;
	}
}

@media screen and (min-width: 768px) and (max-width: 835px) {
	.blue-background {
		height: 22rem;
	}
	.course-description-content {
		margin-top: -4rem;
	}
	.course-description-content hr {
		display: none;
	}
	.course-description-content p {
		margin-top: 4rem;
	}
	.go-to-course-btn {
		margin-top: 18rem;
	}
}

.instructor-profile h4 {
	color: #2f327d;
	margin-bottom: 0;
	font-size: 24px;
}

.instructor-profile p {
	color: #2f327d;
	font-size: 17px;
}

.instructor-profile img {
	border-radius: 100%;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.instructor-profile {
		text-align: center;
	}
}
