.faq-section {
  margin: 100px 0;
}

.faq-section > h2 {
  color: #101828;
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.faq-section > p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #667085;
  margin-bottom: 30px;
}

.faq-section .faq {
  width: 50%;
  margin: 0 auto;
}

.faq div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 50px;
  width: 100%;
}

.faq h4,
.faq-section h4 {
  color: #101828;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}

.faq p {
  color: #667085;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  width: 90%;
}

.faq #icon {
  font-size: 24px;
  fill: #6499e9;
  cursor: pointer;
}

.faq-section button {
  border-radius: 8px;
  border: 1px solid var(--Primary, #6499e9);
  background: var(--Primary, #6499e9);
  color: var(--White, #fff);
  display: flex;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 25px;
  margin: auto;
}

.faq-avatars {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 30px;
}

.faq-avatars img {
  width: 70px;
  height: 70px;
}

.faq-avatars img:nth-child(1) {
  margin-right: -20px;
  margin-top: 5px;
}

.faq-avatars img:nth-child(2) {
  z-index: 2;
}

.faq-avatars img:nth-child(3) {
  margin-left: -20px;
  margin-top: 5px;
  z-index: 1;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {
  .faq-section {
    margin: 0;
    padding: 100px 20px 50px 20px;
  }

  .faq-section > h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .faq-section p {
    font-size: 16px;
  }

  .faq-section .faq {
    width: 100%;
    margin: 0;
  }

  .faq h4,
  .faq-section h4 {
    width: 90%;
    text-align: left;
  }

  .faq-section #faq-h2 {
    text-align: center;
    width: 100%;
  }
}
